import React from "react";
import { graphql } from 'gatsby'
import MDEditor from '@uiw/react-md-editor';
import Layout from '../components/layout'
import { Link } from 'gatsby'

const Newmainpagetemplate = ({data,location}) =>{
  var Index = location.state != null ? location.state.index : 0;
  console.log(data)
  var newmaintemplate = data.strapiMainPages.Mainpagecomponent
  return (
    <Layout>
      <div className="card" style={{boxShadow: '5px 10px 18px #888888',width:"82%"}}>
      <Link to="/Newhome">
      {' '}
      <span style={{ fontSize: '25px' }}>←</span> Back to home
      </Link>
      <MDEditor.Markdown source={newmaintemplate[Index].PageContent}/>
      </div>
      </Layout>
    )
}

export const query = graphql`
query newmainpage_template($Slug: String!){
    strapiMainPages(Mainpagecomponent: {elemMatch: {link: {eq: $Slug}}}) {
        Mainpagecomponent {
          link
          PageContent
        }
      }
  } 
`


export default Newmainpagetemplate